
//
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import ReactDom from "react-dom";
import React from "react";
import ResumedPlan from "../../../../assets/bleucitron/react/whitelabel/resume/ResumedPlan";
import $ from "jquery";
import GdprPassword from "gdpr-password";
import UpdateTraffic from "../../react/traffic/UpdateTraffic";
require('../../css/whitelabel/whitelabel.css');
require('uikit/dist/css/uikit.min.css');
require('../../css/front/bundle.css');
require('../../css/front/bootstrap-datepicker.css');
require('../../css/front/bootstrap-multiselect.min.css');
require( '../../../common/css/_map.scss');

UIkit.use(Icons);

require('./timer.js');


UIkit.util.ready(function () {

    UpdateTraffic();


    /*************************************************************/

    /* DISPLAY SYSTEM ERRORS WITH DELAY (to avoid modal conflicts) */
    var elements = document.getElementsByClassName('modal-error');
    if (elements.length) {
        setTimeout(function() {
            UIkit.modal(elements).show();
        }, 1000);
    }

    let insuranceInfo = document.querySelector('.insuranceChoice');
    let isInsuranceChosen = new Boolean(false);

    if (document.getElementsByClassName("success-message").length >= 1) {
        isInsuranceChosen = true;
    }

    let _linkNonMerci = $('.nonmerci');
    if (_linkNonMerci.length) {

        _linkNonMerci.click(function() {
            _linkNonMerci.closest('.insurance-row').fadeOut(500, function () {
                $(this).remove();
                insuranceInfo.setAttribute("style", "display:none");
                isInsuranceChosen = true;
            })
        })
    }


    /*************************************************************/
    /** VERIFIE QUE LE MOT DE PASSE EST CONFORME RGPD **/
    /*************************************************************/

    function checkPasswordStrength() {

        var passWordUser = document.querySelector('[data-gdpr]');
        if (passWordUser) {

            var gdprform = passWordUser.closest('form');
            new GdprPassword(passWordUser, {form: gdprform});

        }

    }
    function checkPasswordLikeness() {

        $('.js_password_second').keyup(function() {


            let firstPassword = $('.js_password_first'),
                lastPassword = $('.js_password_second'),
                checkerPassword = $('[data-accountpassword="checker-likeness"]');


            if (firstPassword.val() === lastPassword.val()) {

                checkerPassword.removeClass();
                checkerPassword.hide();

            } else {

                checkerPassword.show();
                checkerPassword.addClass('uk-text-danger uk-text-small uk-margin-small-top');
                checkerPassword.text("Les mots de passe saisis ne correspondent pas.");

            }

        });

    }

    checkPasswordStrength();
    checkPasswordLikeness();

    // Affichage des popins : générale + events
    var popins = document.querySelectorAll("[data-popin]");
    Array.prototype.forEach.call(popins, function(popin_el, i){

        UIkit.util.on('#' + popin_el.getAttribute('id'), 'show', function () {
            document.cookie = popin_el.getAttribute('id') + '=' + popin_el.getAttribute('data-popin') + ';path=/;';
        });

        let cookieValue = popin_el.getAttribute('data-popin');
        let cookieName = popin_el.getAttribute('id');
        let regex = new RegExp(`(^| )${cookieName}=([^;]+)`)
        let cookie = document.cookie.match(regex);

        if (!cookie || (cookie[2] !== cookieValue)) {
            UIkit.modal('#' + popin_el.getAttribute('id')).show();
        }

    });


    function onBooked(res) {
        window.location = window.MAP_PROPS.cartUrl;
    }

    function onSeatSelected() {

        var $overlay = $('[data-overlay="loader"]');
        $overlay.addClass('uk-hidden');
        $('.map-btn-addCart-js').removeClass('disabled');

    }

    //
    /***************************************************************/
    /** GESTION DU CHOIX SUR PLAN                                 **/
    /***************************************************************/

    var mapJs = document.querySelector("#map-js");

    if (mapJs) {

        ReactDom.render(
            <ResumedPlan
                sessionId={window.MAP_PROPS.sessionId}
                sessionList={window.MAP_PROPS.sessionList}
                idwl={window.MAP_PROPS.wlId}
                maxSeats={window.MAP_PROPS.maxSeats}
                holders={window.MAP_PROPS.holders}
                onBooked={onBooked}
                onSeatSelected={onSeatSelected}
            />,
            mapJs
        );


        UIkit.util.on('#availabilityMap', 'show', function () {
            ReactDom.render(
                <ResumedPlan
                    sessionId={window.MAP_PROPS.sessionId}
                    sessionList={window.MAP_PROPS.sessionList}
                    idwl={window.MAP_PROPS.wlId}
                    maxSeats={window.MAP_PROPS.maxSeats}
                    holders={window.MAP_PROPS.holders}
                    onBooked={onBooked}
                    onSeatSelected={onSeatSelected}
                    autoload={true}
                />,
                mapJs
            );
        });
    }

    $('.login-form input[type="text"][required="required"]').parent().addClass('required');
    $('.login-form input[type="password"][required="required"]').parent().addClass('required');
    $('.login-form input[type="email"][required="required"]').parent().addClass('required');

});
